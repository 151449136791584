"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.LEAVE = exports.UPDATE = exports.ENTER = void 0;
var ENTER = 'ENTER';
exports.ENTER = ENTER;
var UPDATE = 'UPDATE';
exports.UPDATE = UPDATE;
var LEAVE = 'LEAVE';
exports.LEAVE = LEAVE;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.numeric = numeric;

function numeric(beg, end) {
  var a = +beg;
  var b = +end - a;
  return function (t) {
    return a + b * t;
  };
}